import React from "react"
import { graphql } from "gatsby"
import { StaticQuery } from "gatsby"
import Img from "gatsby-image/index"

const ObjectIcon = ({ id }) => {
  if (!id) {
    id = 0
  }
  return (
    <StaticQuery
      query={graphql`
        query {
          objects: allFile(filter: { sourceInstanceName: { eq: "objects" } }) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 300, quality: 75) {
                    originalName
                    ...GatsbyImageSharpFluid_noBase64
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Img
          style={{
            position: "relative",
            left: "33%",
            top: 0,
            width: "100%",
            maxWidth: "30%",
            maxHeight: "30%",
            height: "30%",
          }}
          imgStyle={{
            objectFit: "contain",
            height: "100%",
          }}
          fluid={
            data.objects.edges.find(element => {
              let fname = element.node.childImageSharp.fluid.src
                .split("/")
                .pop()
              return fname === `ob${id + 1}.png`
            }).node.childImageSharp.fluid
          }
        />
      )}
    />
  )
}

export default ObjectIcon
