import React from "react"
import SolutionsLayout from "../../components/SolutionsLayout"
import helm from "../../images/tools/helm.svg"
import gitlab from "../../images/tools/gitlab.svg"
import github from "../../images/tools/github.svg"

import terraform from "../../images/tools/terraform.svg"
import { Grid } from "@material-ui/core"
import Card from "../../components/Card"

export default function Devops() {
  return (
    <SolutionsLayout>
      <h2 className="heading-2 left">DevOps</h2>
      <p className="paragraph left">
        <strong className="bold-text">
          Accelerate your development cycle by minimizing costs and driving
          efficiency with our DevOps experts. <br />
          <br />
          DevOps is an operational approach that values collaboration,
          communication and integration between software developers and IT
          operations.
        </strong>
        <br />
        <br />
        DevOps automates and standardizes the processes for infrastructure
        deployment. With the help of DevOps, enterprises get faster innovation,
        better operational efficiency, and improved deployment quality that
        enables them to better focus on their business goals.
        <br />
        <br />
        Our cloud DevOps services offer numerous benefits. Through our holistic
        management of the entire Infrastructure as Code lifecycle, we’ll help
        drive operational stability within your organization.
        <br />
        <br />
      </p>
      <h2 className="heading-2 left">Benefits</h2>
      <ul className="list">
        <li>
          Faster <b>time to market</b>
        </li>
        <li>
          Improved <b>efficiency</b>
        </li>
        <li>Continuous improvement</li>
        <li>
          <b>Elasticity</b>: Environments that are durable enough to withstand
          peaks but never costing more than they should
        </li>
        <li>
          Always <b>up-to-date</b> infrastructure
        </li>
        <li>State of the art development methods</li>
        <li>
          Modern interface technologies (ChatOps, self-service dashboards)
        </li>
        <li>Tailored code deployment strategies (canary, blue/green, A/B)</li>
      </ul>
      <h2 className="heading-2 left">What we offer</h2>
      <Grid container alignItems="stretch" className={"w-container"}>
        <Grid item xs={12} lg={6}>
          <Card title="Project development" icon={1}>
            The right tools to break down the functional silos and deliver
            automation at its best.
            <br />
            <br />
            Our team ensures proper software development in a fast paced
            environment.
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Continuous Integration & Continuous Delivery" icon={18}>
            We provide advanced continuous delivery capabilities for increased
            efficiency and automation.
            <br />
            <br />
            <br />
            Build, Test, Release, Monitor & Improve
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Configuration Management" icon={16}>
            We provide error-free and fast deployments, eliminating the risk of
            human errors in the process.
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Infrastructure As Code" icon={2}>
            Our certified team provides you with your very own Infrastructure as
            Code (IaC), an automated programmable and auditable infrastructure.
            Architecting and deploying autoscaling and self-healing
            environments.
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Monitoring & Logging" icon={4}>
            We use latest DevOps tools and technologies integrated with our best
            practices for the process of monitoring and logging.
            <br />
            Analyze data and logs generated by applications & infrastructure
            <br />
            Creating alerts or performing real-time analysis
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Automation" icon={17}>
            We live and breath automation, whether it is with Cloud native
            services or OSS tools.
            <br />
            <br />
            <br />
            We strive to automate all the manual steps in your flows that
            impedes your capability to deliver.
          </Card>
        </Grid>
      </Grid>
      <br />
      <h2 className="heading-2 left">Why partner with us</h2>
      <ul className="list">
        <li>Skilled and experienced DevOps professionals team</li>
        <li>Leveraging automation to reduce your operational costs</li>
        <li>Offering a range of suitable DevOps implementation models</li>
        <li>
          Working out custom DevOps implementation KPIs for your particular
          business project
        </li>
        <li>
          Recommending you with an appropriate DevOps tool chain and assisting
          in its integration
        </li>
      </ul>
      <h3 className="heading-3 left">Some of our favorite tools</h3>
      <Grid container spacing={1} alignItems="stretch">
        <Grid item xs={3}>
          <img height="100" src={gitlab} alt="Gitlab" />
        </Grid>
        <Grid item xs={3}>
          <img height="100" src={github} alt="Gitlab" />
        </Grid>
        <Grid item xs={3}>
          <img height="100" src={terraform} alt="Terraform" />
        </Grid>
        <Grid item xs={3}>
          <img height="100" src={helm} alt="Helm" />
        </Grid>
      </Grid>
    </SolutionsLayout>
  )
}
