import React from "react"
import ObjectIcon from "./ObjectIcon"

export default function Card({ title, icon, children }) {
  if (!icon) {
    icon = 3
  }
  return (
    <div
      className="column w-col w-col-6"
      style={{ width: "100%", height: "100%" }}
    >
      <div className="div-block-6" style={{ height: "100%" }}>
        <ObjectIcon id={icon} />
        <h3 className="heading-3">{title}</h3>
        <p className="paragraph-2">{children}</p>
      </div>
    </div>
  )
}
