import React from "react"
import Layout from "./Layout"
import header_sub from "../images/Header_subpage.svg"
import { Link } from "gatsby"

export default function SolutionsLayout({ children }) {
  return (
    <Layout>
      <div className="header subpage-bg">
        <div className="container">
          <div className="w-layout-grid hero-grid">
            <div className="hero-block">
              <h1 className="heading">
                Solutions
                <br />
              </h1>
              <p className="paragraph-large left">
                Considering using the cloud or heavily invested, we've got you
                covered.
                <br />
              </p>
            </div>
            <div className="hero-image-wrap-subpage">
              <img src={header_sub} alt="" className="hero-image-subpage" />
            </div>
          </div>
        </div>
      </div>
      <div className="content-section subpage" style={{ paddingTop: "20px" }}>
        <div className="columns-3 w-row ">
          <div className="column-left w-col w-col-3 w-col-stack subpage-bg">
            <Link
              to="/solutions/migration"
              activeClassName="active"
              className="link-block-2 w-block"
            >
              <div className="block-left">
                <h3 className="heading-3">Migration</h3>
              </div>
            </Link>
            <Link
              to="/solutions/architecture"
              activeClassName="active"
              className="link-block-2 w-block"
            >
              <div className="block-left">
                <h3 className="heading-3">Architecture</h3>
              </div>
            </Link>
            <Link
              to="/solutions/devops"
              activeClassName="active"
              className="link-block-3 w-block"
            >
              <div className="block-left">
                <h3 className="heading-3">DevOps</h3>
              </div>
            </Link>
            <Link
              to="/solutions/security"
              activeClassName="active"
              className="link-block-3 w-block"
            >
              <div className="block-left">
                <h3 className="heading-3">Security</h3>
              </div>
            </Link>
            <Link
              to="/solutions/costoptimization"
              activeClassName="active"
              className="link-block-3 w-block"
            >
              <div className="block-left">
                <h3 className="heading-3">Cost Optimization</h3>
              </div>
            </Link>
          </div>
          <div className="column-right w-col w-col-9 w-col-stack">
            {children}
          </div>
        </div>
      </div>
    </Layout>
  )
}
